.page-content {
  background: $white;
}

.no-gutters {
  padding-right: 0;
  padding-left: 0;

  > .row {
    margin-left: 0;
    margin-right: 0;
  }
}
