.checkout-cards-view {
  color: $slate-1200;

  .checkout-container.checkout-cards-container {
    .card-wrapper {
      &:last-child {
        margin-bottom: 40px;
      }

      .card-header {
        margin: 32px 0 24px 0;
        letter-spacing: normal;
        display: flex;
        flex-direction: column;
        gap: 8px;

        .card-number {
          font-size: $font-size-base;
        }

        .card-title {
          color: $green-800;
          font-weight: bold;
          font-size: 28px;
        }
        .card-description {
          color: $black;
          font-size: $font-size-lead;
        }
      }

      .card-description-heading {
        font-size: $font-size-base;
        font-weight: bold;
        color: $gray-1200;
        margin-top: 11px;
        margin-bottom: 5px;
      }
      .card-description {
        font-size: $font-size-small;
        color: $gray-1000;
      }

      .preview-card-button {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $white;
        border: 1px solid $pink-800;
        border-radius: 25px;
        width: 100%;
        height: 44px;
        color: $pink-800;
        font-size: $font-size-lead;
        text-transform: none;
        font-weight: bold;
        padding: 0 14px;
        margin-top: 48px;
        text-decoration: none;
        @include transition(background-color 0.3s);

        em {
          font-style: normal;
          padding-left: 22px;
          padding-right: 11px;
          position: relative;

          svg {
            position: absolute;
            width: 16px;
            top: 5px;
            left: -2px;

            g {
              stroke: $pink-800;
            }
          }
        }

        &:hover {
          color: $white;
          background-color: $pink-800;
          svg g {
            stroke: $white;
          }
        }

        @media (min-width: $screen-md-min) {
          max-width: 600px;
        }
      }

      .email-card-form {
        margin-top: 24px;
        margin-bottom: 24px;

        fieldset .form-group {
          max-width: 590px;
        }
        .sending-timestamp {
          > label {
            z-index: 5;
          }

          @media (min-width: $screen-sm-min) {
            .sending-timestamp {
              padding: 0;

              > div:first-child {
                width: 50%;
                padding-left: 6px;
                padding-right: 15px;
                float: right;
              }

              > label {
                position: relative;
                left: 0;
                top: 0;
                font-size: 100%;
                height: 48px;
                margin: 0;
                line-height: 48px;
                display: inline-block;
                float: right;
              }
            }
          }
        }
      }
    }
  }

  //.checkout-summary {
  //  margin-top: 20px;
  //}

  .checkout-cards-continue-container {
    margin-top: 50px;
    text-align: center;
    .btn-checkout {
      display: inline-block;
      font-size: 18px;
      font-weight: bold;
    }

    @media #{$tablet-land} {
      border: none;
      padding-top: 0px;
    }
  }

  .checkout-cards-summary {
    margin-top: 16px;
    font-size: 14px;
    font-weight: $font-weight-regular;

    @media (min-width: $screen-sm-min) {
      font-size: 16px;
    }
  }
}
