//
// Scaffolding
// --------------------------------------------------

// Reset the box-sizing
//
// Heads up! This reset may cause conflicts with some third-party widgets.
// For recommendations on resolving such conflicts, see
// http://getbootstrap.com/getting-started/#third-box-sizing
* {
  @include box-sizing(border-box);
}
*:before,
*:after {
  @include box-sizing(border-box);
}

// Body reset
html * {
  text-rendering: optimizeLegibility;
}
html {
  font-size: 10px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
@-ms-viewport {
  width: device-width;
}

body {
  margin: 0;
  font-family: $font-family-base;
  font-size: $font-size-base;
  font-weight: $text-weight;
  line-height: $line-height-base;
  color: $text-color;
  background-color: $white;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

// Reset fonts for relevant elements
input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

// Links
a {
  color: $link-color;
  text-decoration: underline;
  @include transition(color 0.3s);

  &:hover {
    color: $link-hover-color;
    text-decoration: none;
  }
  &:focus {
    color: $link-hover-color;
    outline: none;
  }
}
a.link-title {
  color: $text-color;
  text-decoration: none;
  &:hover {
    color: $gray-400;
  }
}

// Images
// Responsive images (ensure images don't scale beyond their parents)
img {
  vertical-align: middle;
  @include img-responsive;
}
svg {
  max-width: 100%;
}

// Rounded corners
.img-rounded {
  border-radius: $border-radius-big;
}

// Perfect circle
.img-circle {
  border-radius: 50%; // set radius in percents
}

// Opacity values
.opacity-75 {
  opacity: 0.75;
}
.opacity-50 {
  opacity: 0.5;
}
.opacity-25 {
  opacity: 0.25;
}

.display-inline {
  display: inline-block !important;
  vertical-align: middle;
  img {
    display: block;
    width: 100%;
  }
}

// Horizontal rules
hr {
  margin-top: $line-height-computed;
  margin-bottom: $line-height-computed;
  border: 0;
  border-top: 1px solid $hr-border;
}

.block {
  display: block;
}
.inline {
  display: inline-block;
  img {
    display: block;
    width: 100%;
  }
}
.relative {
  position: relative !important;
}
.absolute {
  position: absolute !important;
}

// Only display content to screen readers
//
// See: http://a11yproject.com/posts/how-to-hide-content/
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

// Use in conjunction with .sr-only to only display content when it's focused.
// Useful for "Skip to main content" links; see http://www.w3.org/TR/2013/NOTE-WCAG20-TECHS-20130905/G1
// Credit: HTML5 Boilerplate
.sr-only-focusable {
  &:active,
  &:focus {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto;
  }
}

// Text Selection Color
::selection {
  color: $text-color;
  background: $gray-100;
}
::-moz-selection {
  color: $text-color;
  background: $gray-100;
}

kbd {
  background-color: $gray-1200;
  border-radius: 0;
}

// Vertical alignment
.va-middle {
  vertical-align: middle;
}
.va-top {
  vertical-align: top;
}
.va-bottom {
  vertical-align: bottom;
}

// Vertical Space classes
.space-top-half {
  margin-top: floor($line-height-computed * 0.5) !important;
}
.space-top {
  margin-top: $line-height-computed !important;
}
.space-top-2x {
  margin-top: floor($line-height-computed * 2) !important;
}
.space-top-3x {
  margin-top: floor($line-height-computed * 3) !important;
}
.space-bottom-half {
  margin-bottom: floor($line-height-computed * 0.5) !important;
}
.space-bottom {
  margin-bottom: $line-height-computed !important;
}
.space-bottom-2x {
  margin-bottom: floor($line-height-computed * 2) !important;
}
.space-bottom-3x {
  margin-bottom: floor($line-height-computed * 3) !important;
}
.padding-top-half {
  padding-top: floor($line-height-computed * 0.5) !important;
}
.padding-top {
  padding-top: $line-height-computed !important;
}
.padding-top-2x {
  padding-top: floor($line-height-computed * 2) !important;
}
.padding-top-3x {
  padding-top: floor($line-height-computed * 3) !important;
}

.padding-right {
  padding-right: $line-height-computed !important;
}

.padding-left {
  padding-left: $line-height-computed !important;
}

.padding-bottom-half {
  padding-bottom: floor($line-height-computed * 0.5) !important;
}
.padding-bottom {
  padding-bottom: $line-height-computed !important;
}
.padding-bottom-2x {
  padding-bottom: floor($line-height-computed * 2) !important;
}
.padding-bottom-3x {
  padding-bottom: floor($line-height-computed * 3) !important;
}

.padding-horizontal-150 {
  padding-left: 150px;
  padding-right: 150px;
}

@media (min-width: $screen-md) {
  .padding-horizontal-150-md {
    padding-left: 150px;
    padding-right: 150px;
  }

  .padding-horizontal-100-md {
    padding-left: 100px;
    padding-right: 100px;
  }

  .padding-horizontal-60-md {
    padding-left: 60px;
    padding-right: 60px;
  }

  .padding-horizontal-10-md {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: $screen-xlg) {
  .padding-horizontal-150-xl {
    padding-left: 150px;
    padding-right: 150px;
  }

  .padding-horizontal-100-xl {
    padding-left: 100px;
    padding-right: 100px;
  }
}

@media (min-width: $screen-lg) {
  .padding-horizontal-150-lg {
    padding-left: 150px;
    padding-right: 150px;
  }

  .padding-horizontal-100-lg {
    padding-left: 100px;
    padding-right: 100px;
  }
}

@media #{$tablet-port} {
  .space-top {
    // Division by 1.5
    margin-top: floor($line-height-computed * 0.6666) !important;
  }
  .space-top-2x {
    margin-top: floor($line-height-computed * 1.5) !important;
  }
  .space-top-3x {
    margin-top: floor($line-height-computed * 2) !important;
  }
  .space-bottom {
    margin-bottom: floor($line-height-computed * 0.6666) !important;
  }
  .space-bottom-2x {
    margin-bottom: floor($line-height-computed * 1.5) !important;
  }
  .space-bottom-3x {
    margin-bottom: floor($line-height-computed * 2) !important;
  }
  .padding-top {
    padding-top: floor($line-height-computed * 0.6666) !important;
  }
  .padding-top-2x {
    padding-top: floor($line-height-computed * 1.5) !important;
  }
  .padding-top-3x {
    padding-top: floor($line-height-computed * 2) !important;
  }
  .padding-bottom {
    padding-bottom: floor($line-height-computed * 0.6666) !important;
  }
  .padding-bottom-2x {
    padding-bottom: floor($line-height-computed * 1.5) !important;
  }
  .padding-bottom-3x {
    padding-bottom: floor($line-height-computed * 2) !important;
  }
}
.space-top-none {
  margin-top: 0 !important;
}
.space-bottom-none {
  margin-bottom: 0 !important;
}
.padding-top-none {
  padding-top: 0 !important;
}
.padding-bottom-none {
  padding-bottom: 0 !important;
}

.page-edit .edit-link {
  display: inline-block;
  margin-top: $line-height-computed;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}

.block-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.tablet-center {
  @media #{$tablet-land} {
    text-align: center !important;
  }
}
.mobile-center {
  @media #{$tablet-port} {
    text-align: center !important;
  }
}

/* Text meant only for screen readers. */
.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
}
.screen-reader-text:focus {
  background-color: $gray-100;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  color: $blue-1000;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: bold;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000; /* Above WP toolbar. */
}

// Container Fluid
.container-fluid {
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 30px;
}

.main-noscript {
  position: fixed;
  z-index: 100;
  background-color: $white;
  width: 100%;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
}
