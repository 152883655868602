.blocks-wrapper {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1440px;
  margin: auto;
}

.block-subheading {
  text-align: center;
  width: 100%;
}

.block-info-card {
  margin-bottom: 32px;
}
