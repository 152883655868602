.navbar {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);

  .main-navigation {
    top: 0;
    height: calc(100vh + 34px);
    margin-top: 98px;
    background-color: $green-800;
    padding: 80px 32px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .menu li > a {
      font-size: 22px;
      font-weight: bold;
      color: $white;
    }

    .mobile-menu-region-switcher-wrapper {
      color: $white;
      position: relative;
      font-size: 12px;
      margin-bottom: 185px;
      display: flex;
      gap: 12px;

      .mobile-menu-region-switcher {
        position: relative;
        left: 0;
        top: 0;
      }
    }
  }

  &.without-topbar .main-navigation {
    margin-top: 64px;
  }

  @include media-breakpoint-up(md) {
    .main-navigation {
      .mobile-menu-region-switcher-wrapper {
        margin-bottom: 200px;
      }

      margin-top: 114px;
    }

    &.without-topbar .main-navigation {
      margin-top: 80px;
    }
  }
  @include media-breakpoint-up(xl) {
    .main-navigation {
      height: auto;
      margin-top: 0;
      background-color: transparent;
      padding: 0 130px 0 180px;
      text-align: center;
      display: block;

      .menu li > a {
        font-size: 15px;
        font-weight: normal;
        color: $gray-1200;
        padding: 0 15px;

        &.active {
          background-color: $gray-50;
          &:hover {
            color: $gray-600;
          }
        }

        &.christmas {
          font-weight: bold;
          color: $red-1000;
          &:hover {
            color: $red-1200;
          }
        }
      }

      .mobile-menu-region-switcher-wrapper {
        display: none;
      }
    }

    &.without-topbar .main-navigation {
      margin-top: 0;
    }
  }
}

// Go to Basket widget.
.toolbar {
  right: 15px;
  width: 50px;

  @include media-breakpoint-up(md) {
    right: 30px;
  }

  .inner {
    .cart-btn {
      & > a {
        padding: 28px 0;

        &:hover,
        &:active {
          svg path {
            stroke: $gray-600;
          }
        }

        @media #{$tablet-port} {
          padding: 20px 0;
        }

        .count {
          width: 20px;
          height: 20px;
          top: 16px;
          right: 0;
          font-size: $font-size-small;
          line-height: 19px;

          @media #{$tablet-port} {
            top: 10px;
          }
        }

        .text {
          display: inline-block;
          margin-left: 10px;
          font-size: 15px;
        }
      }
    }
  }
}

// Mobile menu.
.mobile-menu-wrapper {
  display: inline-block;
  position: absolute;
  left: 10px;
  top: 5px;
  z-index: 100;

  @media (min-width: $screen-sm-min) {
    left: 20px;
    top: 14px;
  }

  .mobile-menu-toggle {
    background-color: transparent;
    display: none;
    padding: 10px;
    color: $turquoise-1200;

    &:after {
      display: block;
      font-family: 'Material Icons';
      content: '\e5cd';
      margin-top: -30px;
      opacity: 0;
      @include transform(translateY(30px) scale(0.7));
      @include transition(transform 0.3s, opacity 0.15s);
    }
    &.active {
      > i {
        opacity: 0;
        @include transform(translateY(-30px) scale(0.7));
      }
      &:after {
        opacity: 1;
        @include transform(translateY(0) scale(1));
      }
    }

    .material-icons.menu {
      &:before {
        font-size: 24px;
        line-height: 24px;

        @media (min-width: $screen-sm-min) {
          font-size: 30px;
          line-height: 30px;
        }
      }
    }

    @media #{$navbar-collapse} {
      display: table-cell;
      margin: 0;
      vertical-align: middle;
      height: 100%;
      border: 0;
      line-height: 30px;
    }
  }
}

// Site logo.
.site-logo-wrapper {
  position: absolute;
  left: calc(50% - 45px);

  @include media-breakpoint-up(md) {
    left: calc(50% - 75px);
  }

  @include media-breakpoint-up(xl) {
    left: 30px;
  }

  @media (min-width: $screen-xlg) {
    left: 36px;
  }
}

.site-logo.visible-mobile {
  padding: 17px 0;
}
