//
// Site Navbar (Header)
// --------------------------------------------------

// Navbar Base Class
.navbar {
  display: block;
  padding: 0;
  position: relative;
  z-index: $navbar-zindex;
  width: 100%;
  background-color: $navbar-bg;
  top: 0;
  left: 0;
  &.navbar-sticky {
    position: sticky;
    transition: 0.3s all;
  }
}

// Site logo
.site-logo {
  position: relative;
  z-index: 10;
  display: inline-block;
  vertical-align: middle;
  color: $text-color;
  font-size: floor($font-size-base * 1.25); // 20px
  font-weight: $font-weight-regular;
  text-decoration: none;
  padding: 20px 0;
  margin-right: 20px;
  margin-top: 0;
  width: 130px;
  span {
    font-weight: $font-weight-regular;
  }
  &:hover,
  &:focus,
  &:active {
    color: $text-color;
    text-decoration: none;
  }
  img {
    width: 100%;
    image-rendering: -moz-crisp-edges; /* Firefox */
    image-rendering: -o-crisp-edges; /* Opera */
    image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
  }
  &.visible-mobile {
    display: none;
  }
  @media #{$tablet-port} {
    z-index: 20;
    margin-right: 15px;
    &.visible-desktop {
      display: none;
    }
    &.visible-mobile {
      display: inline-block;
      width: 90px;
      padding: 25px 0;
    }
  }
  @media #{$mobile-port} {
    margin-right: 8px;
  }
}

// Language Switcher
.lang-switcher {
  position: absolute;
  z-index: 10;
  vertical-align: middle;
  cursor: pointer;
  left: 160px;
  top: 21px;
  display: block;

  @media (min-width: $screen-sm-min) {
    left: 240px;
    top: 28px;
  }

  @media #{$navbar-collapse-min} {
    left: 190px;
  }

  @media (min-width: $screen-xlg) {
    left: 180px;
  }

  .lang-toggle {
    cursor: pointer;
    display: inline-block;
    position: relative;
    margin-left: 12px;
    > img,
    > i {
      display: inline-block;
      vertical-align: middle;
    }
    > img {
      width: $flag-size;
    }
    > i {
      color: $white;
      font-size: $dropdown-arrow-size;
      line-height: 1;
      margin-left: -2px;
      @include transform(rotate(0deg));
      @include transition(all 0.25s);
    }
  }
  .lang-dropdown {
    display: block;
    position: absolute;
    right: 8px;
    width: 64px;
    list-style: none;
    padding: 5px 7px;
    margin: 0;
    margin-top: 5px;
    top: 100%;
    left: -7px;
    background-color: $lang-dropdown-bg;
    visibility: hidden;
    opacity: 0;
    @include box-shadow(0px 4px 16px 0px rgba(0, 0, 0, 0.15));
    @include transform(translateY(-15px) scale(0.95));
    @include transition(all, 0.3s $ease-out-back);
    > li {
      margin-bottom: 6px;
      &:last-child {
        margin-bottom: 0;
      }
      > a {
        font-size: $font-size-small;
        color: $text-color;
        text-decoration: none;
        text-align: center;
        display: block;
        opacity: 1;
        @include transition(opacity, 0.25s);
        > img {
          display: inline-block;
          vertical-align: middle;
          width: $flag-size;
          margin-right: 7px;
        }
        &:hover {
          opacity: 0.6;
        }
      }
    }
  }
  &.open {
    .lang-toggle > i {
      @include transform(rotate(180deg));
    }
    .lang-dropdown {
      visibility: visible;
      opacity: 1;
      @include transform(translateY(0) scale(1));
    }
  }
  @media #{$tablet-port} {
    z-index: 20;
  }
}

// Toolbar
.toolbar {
  position: absolute;
  z-index: 10;
  width: $toolbar-width-desktop;
  top: 0;
  right: 30px;
  bottom: 0;

  .inner {
    display: table;
    width: 100%;
    height: 100%;
    table-layout: fixed;
    > a,
    .cart-btn > a {
      font-size: $toolbar-toggle-icon-size;
      color: $text-color;
      text-decoration: none;
      line-height: 1;
      @include transition(all 0.3s);
      > svg {
        position: relative;
        display: inline-block;
        font-style: normal;
        opacity: 1;
        @include transform(translateY(0) scale(1));
        @include transition(transform 0.3s, opacity 0.15s);
      }
    }
    .cart-btn {
      cursor: pointer;
      > a {
        display: block;
        padding: 20px;
      }
    }
    > a,
    > button,
    > .cart-btn {
      .count {
        position: absolute;
        display: block;
        width: 16px;
        height: 16px;
        line-height: 16px;
        font-size: $font-size-xs;
        color: $white;
        text-align: center;
        border-radius: 50%;
        background-color: $pink-1200;
        top: -8px;
        right: -17px;
      }
      &.mobile-menu-toggle {
        display: none;
        &:after {
          display: block;
          font-family: 'Material Icons';
          content: '\e5cd';
          margin-top: -$toolbar-toggle-icon-size;
          opacity: 0;
          @include transform(translateY(20px) scale(0.7));
          @include transition(transform 0.3s, opacity 0.15s);
        }
        &.active {
          > i {
            opacity: 0;
            @include transform(translateY(-20px) scale(0.7));
          }
          &:after {
            opacity: 1;
            @include transform(translateY(0) scale(1));
          }
        }
      }
    }
  }
}

// Cart Dropdown
.cart-dropdown {
  display: none;
  position: absolute;
  width: $cart-dropdown-width;
  right: -15px;
  top: 100%;
  list-style: none;
  margin: 0;
  margin-top: -14px;
  padding: 17px 20px;
  text-align: left;
  cursor: default;
  background: $white;
  border-radius: $border-radius-big;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  z-index: 3;

  .total {
    display: flex;
    justify-content: space-between;
    font-size: $font-size-small;
    margin-bottom: 15px;
  }
}
.toolbar {
  .cart-btn:hover {
    .cart-dropdown {
      display: block;
      @include animation(slideUp 0.4s);
    }
  }
}

// Main Navigation
.main-navigation {
  display: block;
  position: absolute;
  z-index: 5;
  width: 100%;
  left: 0;
  padding: {
    right: ($toolbar-width-desktop + 60);
    left: ($image-logo-width + 65);
  }
  > .menu {
    list-style: none;
    padding: 0;
    margin: 0;
    li > a {
      display: block;
      color: $nav-link-color;
      font: {
        size: $nav-link-font-size;
        weight: $font-weight-regular;
      }
      text: {
        decoration: none;
      }
    }
    > li {
      display: inline-block;
      position: relative;
      > a {
        line-height: ($navbar-min-height - 34);
      }
      &.current-menu-item > a,
      &.current_page_item > a,
      &.current-menu-ancestor > a,
      &.current-menu-parent > a,
      &:hover > a,
      &.active > a {
        color: $nav-link-hover-color;
      }

      // Sub-Menu
      //.sub-menu {
      //  display: none;
      //  position: absolute;
      //  width: $submenu-width;
      //  left: 0;
      //  top: 100%;
      //  list-style: none;
      //  margin: 0;
      //  margin-top: -14px;
      //  padding: 20px;
      //  text-align: left;
      //  background-color: $submenu-bg-color;
      //  border: $submenu-border;
      //  > li {
      //    margin-top: 8px;
      //    &:first-child {
      //      margin-top: 0;
      //    }
      //    > a {
      //      font-size: $submenu-link-font-size;
      //    }
      //    &.current-menu-item > a,
      //    &.current_page_item > a,
      //    &.current-menu-ancestor > a,
      //    &.current-menu-parent > a,
      //    &:hover > a {
      //      color: $nav-link-hover-color;
      //    }
      //  }
      //}
      //&.menu-item-has-children {
      //  > a:after {
      //    display: inline-block;
      //    vertical-align: middle;
      //    font-family: 'Material Icons';
      //    content: '\e5c5';
      //    font-size: 1.2em;
      //    margin-left: 1px;
      //    margin-top: -2px;
      //    @include transform(rotate(0));
      //    @include transition(transform 0.3s);
      //  }
      //}
      //&:hover {
      //  .sub-menu {
      //    display: block;
      //    @include animation(slideUp 0.4s);
      //  }
      //}
    }
  }
  &.text-center {
    > .menu > li > a {
      @include media-breakpoint-up(xl) {
        padding-right: 15px;
        padding-left: 15px;
      }
    }
  }
  &.text-right {
    > .menu > li > a {
      padding-right: 0;
      padding-left: 30px;
    }
  }
}

// Mobile Menu Togle
.mobile-menu-toggle {
  font-size: 24px;
  > i {
    position: relative;
    display: inline-block;
    font-size: 24px;
    font-style: normal;
    line-height: 24px;
    opacity: 1;
    @include transform(translateY(0) scale(1));
    @include transition(transform 0.3s, opacity 0.15s);
  }
  &:after {
    display: block;
    font-family: 'Material Icons';
    content: '\e5cd';
    margin-top: -$toolbar-toggle-icon-size;
    opacity: 0;
    @include transform(translateY(20px) scale(0.7));
    @include transition(transform 0.3s, opacity 0.15s);
  }
  &.active {
    > i {
      opacity: 0;
      @include transform(translateY(-20px) scale(0.7));
    }
    &:after {
      opacity: 1;
      @include transform(translateY(0) scale(1));
    }
  }
}

// Navbar Collapse
@media #{$navbar-collapse} {
  .toolbar {
    width: $toolbar-width-mobile;
    .inner > button {
      &.mobile-menu-toggle {
        display: table-cell;
        margin: 0;
        vertical-align: middle;
        height: 100%;
        border: 0;
        line-height: 24px;
      }
    }
  }
  .toolbar .inner .cart-btn:hover .cart-dropdown {
    display: none;
  }
  .main-navigation {
    position: fixed;
    width: 100%;
    height: calc(100% - #{$navbar-min-height});
    top: $navbar-min-height;
    left: 0;
    background-color: $mobile-menu-bg-color;
    padding: 0;
    overflow-y: auto;
    visibility: hidden;
    opacity: 0;
    @include transition(visibility 0.3s, opacity 0.3s);
    > .menu {
      display: none;
      padding: 20px 0;
      > li {
        display: block;
        > a {
          line-height: $line-height-base;
          padding: 12px 0;
        }
        //.sub-menu {
        //  display: block;
        //  position: relative;
        //  width: 100%;
        //  max-height: 0;
        //  left: 0;
        //  top: 0;
        //  margin: 0;
        //  margin-top: 0;
        //  padding: 0;
        //  text-align: center;
        //  background-color: $gray-100;
        //  border: 0;
        //  overflow: hidden;
        //  @include transition(all 0.4s);
        //  > li {
        //    margin-top: 0;
        //    > a {
        //      padding: 6px 15px;
        //    }
        //  }
        //}
        //&:hover .sub-menu {
        //  @include animation(none);
        //}
        &.menu-item-has-children.active {
          > a:after {
            @include transform(rotate(180deg));
          }
          .sub-menu {
            max-height: 600px;
            padding: 20px 0;
          }
        }
      }
    }
    &.open {
      visibility: visible;
      opacity: 1;
      > .menu {
        display: block;
        @include animation(slideUpLong 0.5s);
      }
    }
  }
}

.switch-transition-enter {
  opacity: 0;

  &.switch-transition-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-out;
  }
}
.switch-transition-exit {
  &.switch-transition-exit-active {
    opacity: 0;
    transition: opacity 300ms ease-out;
  }
}

@media #{$tablet-port} {
  .navbar {
    padding-left: 0;
  }
  .toolbar {
    right: 20px;
  }
}

// slideUp
@include keyframes(slideUp) {
  from {
    opacity: 0;
    @include transform(translate3d(0, 15px, 0));
  }
  to {
    opacity: 1;
    @include transform(none);
  }
}
@include keyframes(slideUpLong) {
  from {
    opacity: 0;
    @include transform(translate3d(0, 25px, 0));
  }
  to {
    opacity: 1;
    @include transform(none);
  }
}
