.info-icon-wrapper {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 0;
  right: 16px;
  //background-image: url(../img/info-icon.svg);
  z-index: 10;
  cursor: pointer;

  path.fill {
    fill: $text-color;
  }

  &:hover {
    path.fill {
      fill: $pink-1200;
    }
  }
}
