//@import "~bootstrap/scss/containers";
//@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/mixins/breakpoints";

.head {
  position: relative;
}

//.container {
//  margin-left: auto;
//  margin-right: auto;
//  padding-left: 15px;
//  padding-right: 15px;
//  width: 100%;
//
//  //@media (min-width: $screen-sm) {
//  //  padding: 0;
//  //}
//
//  @media (min-width: $screen-md) {
//    max-width: $container-xlg;
//    padding-left: 30px;
//    padding-right: 30px;
//  }
//
//  @media (min-width: $screen-xlg) {
//    padding-left: 15px;
//    padding-right: 15px;
//  }
//}

// set base padding for no-margin container to double, but only for smallest displays
.container {
  width: 100%;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;


  .row {
    margin-right: -20px;
    margin-left: -20px;

    [class*='col-'] {
      padding-left: 20px;
      padding-right: 20px;
      margin-bottom: 24px;
    }
  }

  &.compact-grid {
    .row {
      margin-left: -6px;
      margin-right: -6px;

      [class*='col-'] {
        padding-left: 6px;
        padding-right: 6px;
        margin-bottom: 12px;
      }
    }
  }


  @include media-breakpoint-up(xs) {
    max-width: 540px;
  }
  @include media-breakpoint-up(md) {
    max-width: 720px;
  }

  @include media-breakpoint-up(lg) {
    &.wide-grid {
      overflow: hidden;

      .row {
        margin-left: -20px;
        margin-right: -20px;

        [class*='col-'] {
          padding-left: 20px;
          padding-right: 20px;
          margin-bottom: 40px;
        }
      }
    }
  }
}

.container-xl, .container-lg, .container-md, .container-sm, .container {
  @include media-breakpoint-up(lg) {
    max-width: 1100px;
    // We use 70px padding to avoid overlapping with Share block on desktop.
    padding-left: 70px;
    padding-right: 70px;
  }

  @include media-breakpoint-up(xl) {
    max-width: 1420px;
  }
}


.header-container {
  position: relative;
  max-width: none;
  min-height: calc($navbar-min-height-mobile - 34px);

  @media (min-width: $screen-sm) {
    min-height: calc($navbar-min-height - 34px);
    padding-left: 36px;
    padding-right: 36px;
  }
}

