.product-badge {
  position: absolute;
  top: 4px;
  right: 6px;
  width: 70px;
  height: 70px;
  @media (min-width: $screen-sm-min) {
    top: 10px;
    right: 10px;
    width: 80px;
    height: 80px;
  }
}
