// Shop Item
.shop-item {
  position: relative;

  .shop-item-title {
    width: 70%;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 8px;

    > a {
      color: $green-1000;
      text-decoration: none;
    }

    @media (min-width: $screen-md-min) {
      font-size: 20px;
    }
  }

  .shop-item-details {
    padding-top: 8px;

    .shop-item-price {
      text-align: left;
    }
  }
}
