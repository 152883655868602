// Typography enhancements.
h1 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 12px;

  @media (min-width: $screen-sm-min) {
    font-size: $font-size-h1;
    margin-bottom: $line-height-computed * 0.65;
  }
}

h5 {
  font-weight: bold;
  margin-bottom: 20px;
  margin-top: 40px;

  @media (min-width: $screen-md) {
    margin-bottom: 30px;
    margin-top: 64px;
  }
}

p {
  margin: 0 0 10px;
  @media (min-width: $screen-sm-min) {
    margin-bottom: 16px;
  }
}

.lead-md {
  // Enable .lead class for md+ screens only.
  @media (min-width: $screen-sm-min) {
    font-size: $font-size-lead;
  }
}

b,
strong {
  font-weight: bold;
}
