// Contextual backgrounds

// [converter] $parent hack
@mixin bg-variant($parent, $color) {
  #{$parent} {
    background-color: $color;
  }
  a#{$parent}:hover {
    background-color: darken($color, 5%);
  }
}
