// Add support of SVG icons to buttons.
.btn-primary {
  path.fill {
    fill: $white;
  }

  &:hover {
    svg path.fill {
      fill: $white;
    }
  }

  &:active {
    svg path.fill {
      fill: $green-600;
    }
  }
}

// Override btn-info.
.btn-info {
  background-color: $white;
  color: $green-600;
  border-color: $green-600;
  border-width: 1px;

  &:hover,
  &:active,
  &:focus {
    color: $btn-font-color-alt !important;
    background-color: $green-800 !important;
    border-color: transparent;
  }
}

// Introduce new button type.
.btn.btn-secondary {
  text-transform: none;
  background-color: transparent;
  color: $gray-800;
  border: solid 1px $gray-400;

  path.fill {
    fill: $gray-600;
  }

  &:hover {
    color: $green-600;
    border-color: $green-600;

    svg path.fill {
      fill: $green-600;
    }
  }

  &:active {
    color: $green-800 !important;
    border-color: $green-800;

    svg path.fill {
      fill: $green-800;
    }
  }
}

// Introduce cusom buttom size.
.btn-small {
  text-transform: none;
  height: 32px;
  line-height: 28px;
  padding: 0 12px 0 11px;
  margin: 0;
  font-size: 12px;

  @media (min-width: $screen-sm-min) {
    height: 36px;
    line-height: 32px;
    font-size: 14px;
  }
}
