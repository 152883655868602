// Make drop-down arrows to open select list.
.form-element {
  &.form-select:before {
    pointer-events: none;
  }
}

// Add currency symbols to the inputs.
.form-group.currency-EUR,
.form-group.currency-GBP {
  .form-group.field-integer {
    position: relative;

    input {
      padding-left: 32px;
    }

    &:before {
      position: absolute;
      top: 12px;
      left: 12px;
      pointer-events: none;
    }
  }
}

.form-group.currency-EUR .form-group.field-integer:before {
  content: '€';
}
.form-group.currency-GBP .form-group.field-integer:before {
  content: '£';
}

form.options > .form-group {
  margin-bottom: 0;
}

.text-gray p a {
  color: $text-gray;
}

// Fixing images inside label element in IE11.
label img {
  pointer-events: none;
  position: relative;
}
