.basket-summary {
  margin-bottom: 16px;

  .amount {
    display: inline-block;
    font-weight: bold;
  }
}

.basket-summary-with-gifts {
  border: $cart-dropdown-border;
  border-radius: $border-radius-big;
  margin-bottom: 15px;
  padding: 25px 20px;
  width: 100%;

  a {
    color: $nav-link-color;
    text-decoration: none;
    &:hover,
    &:focus {
      color: $green-600;
      text-decoration: underline;
    }
  }

  .basket-summary-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 9px;
  }

  .basket-summary {
    margin-top: 16px;
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .amount {
      font-size: $font-size-big;
    }
  }
}

.cart-dropdown,
.basket-summary-with-gifts .basket-summary-products,
.basket-banner-products {
  .item {
    padding-bottom: 9px;
    border-bottom: $cart-dropdown-border;
    display: flex;
    margin-bottom: 13px;

    &.custom-donation .item-thumb {
      picture,
      img {
        height: 100%;
        object-fit: cover;
      }
    }

    .item-thumb {
      flex-shrink: 0;
      padding-right: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 90px;

      picture,
      img {
        object-fit: contain;
        max-width: 75px;
        max-height: 75px;
      }

      .with-img-background {
        background-size: cover;
        background-position: center;
        height: 75px;
        width: 75px;
      }
    }

    .item-details {
      flex-grow: 1;
      display: flex;
      min-height: 73px;
      flex-direction: column;

      .item-title {
        margin-bottom: 5px;
        font-weight: bold;
        color: $gray-1200;
        font-size: $font-size-base;

        a {
          color: $gray-1200;
          text-decoration: none;

          &:hover,
          &:focus {
            color: $green-600;
            text-decoration: underline;
          }
        }
      }

      .item-price {
        margin-bottom: 5px;
        font-size: $font-size-base;
      }

      .remove-button {
        font-size: $font-size-small;
        cursor: pointer;

        &:hover {
          color: $red-600;
          text-decoration: underline;
        }
        &:active {
          color: $pink-1000;
        }
      }
    }
  }
}