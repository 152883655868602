//
// Tabs + Filters
// --------------------------------------------------

.nav-tabs {
  margin-bottom: 0;
  padding-left: 0;
  > li {
    position: relative;
    display: inline-block;
    margin-bottom: 0;
    margin-bottom: -2px;
    > a {
      @extend .btn;
    }

    // Active state, and its :hover to override normal :hover
    &.active > a {
      @extend .btn-primary;
    }
  }
}

.react-tabs__list {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  border: none;
  border-bottom: 2px solid $hr-border;

  > li {
    display: inline-block;
    margin-bottom: -2px;
    position: relative;
    z-index: 0;
    color: $nav-tabs-link-color;
    font-size: $nav-tabs-font-size;
    text-decoration: none;
    font-weight: $font-weight-regular;
    text-transform: uppercase;
    padding: 18px;
    line-height: $line-height-base;
    background-color: transparent;
    border: none;
    border-bottom: 2px solid transparent;
    border-radius: 0;
    cursor: pointer;
    @include user-select(none);
    @include transition(all 0.3s);
    &:hover,
    &:focus {
      text-decoration: none;
      outline: none;
    }
    &:hover {
      color: $nav-tabs-link-active-color;
      background: none;
    }
    // Active state, and its :hover to override normal :hover
    &.react-tabs__tab--selected {
      &,
      &:hover,
      &:focus {
        color: $nav-tabs-link-active-color;
        background: none;
        border: none;
        cursor: default;
        border-bottom: 2px solid $nav-tabs-link-active-color;
      }
    }
  }
}

.react-tabs {
  .wide-image img {
    width: 100%;
  }

  .content img {
    &[data-align='center'] {
      text-align: center;
      margin: 0 auto;
    }

    &[data-align='left'] {
      float: left;
    }

    &[data-align='right'] {
      float: right;
    }
  }
}

// Tabbable tabs
.tab-content {
  padding-top: floor($line-height-computed * 1.5);
  > .tab-pane {
    display: none;
  }
  > .active {
    display: block;
  }
}

// Component Animations
.transition {
  &.fade {
    opacity: 0;
    @include transition(all 0.4s ease-in-out);
    @include backface-visibility(hidden);
    &.in {
      opacity: 1;
    }
  }
  &.scale.fade {
    @include transform(scale(0.9));
  }
  &.scaledown.fade {
    @include transform(scale(1.1));
  }
  &.scale.fade.in,
  &.scaledown.fade.in {
    @include transform(scale(1));
  }
  &.left.fade {
    @include transform(translateX(40px));
  }
  &.right.fade {
    @include transform(translateX(-40px));
  }
  &.left.fade.in,
  &.right.fade.in {
    @include transform(translateX(0px));
  }
  &.top.fade {
    @include transform(translateY(-40px));
  }
  &.bottom.fade {
    @include transform(translateY(40px));
  }
  &.top.fade.in,
  &.bottom.fade.in {
    @include transform(translateY(0px));
  }
  &.flip.fade {
    @include transform(rotateY(-90deg) scale(1.1));
    @include transform-origin(50% 50%);
  }
  &.flip.fade.in {
    @include transform(rotateY(0deg) scale(1));
    @include transform-origin(50% 50%);
  }
}

// Filters
// --------------------------------------------------
.nav-filters {
  display: none;
  list-style: none;
  font-size: $nav-filters-font-size;
  text-transform: uppercase;
  margin: 0;
  position: absolute;
  z-index: 10;
  background: $white;
  box-shadow: 1px 1px 7px 0px rgba(171, 171, 171, 1);
  padding: 16px;
  @media (min-width: $screen-lg) {
    font-size: $nav-filters-font-size;
    display: block;
    position: relative;
    box-shadow: none;
    padding: 0;
    margin: 0;
    background: transparent;
  }
  &.collapsed {
    display: block;
  }
  .clear-btn {
    position: absolute;
    right: 4px;
    top: 4px;
    font-size: 18px;
    background: none;
    border: none;
    &:hover,
    &:active,
    &:focus {
      outline: none;
      color: $pink-1200;
    }
    @media (min-width: $screen-lg) {
      display: none;
    }
  }

  > li {
    @media (min-width: $screen-lg) {
      display: inline-block;
    }
    margin: 0 22px 10px 0;
    > a {
      color: $nav-filters-link-color;
      text-decoration: none;
      padding-bottom: 8px;
      border-bottom: 8px solid transparent;
    }
    sup {
      color: $nav-filters-count-color;
    }
    > a:hover,
    &.active > a {
      color: $nav-filters-link-active-color;
    }
    &.active > a {
      @media (min-width: $screen-lg) {
        border-bottom-color: $nav-filters-link-active-border-color;
      }
    }
  }
}
.text-center {
  .nav-filters,
  &.nav-filters {
    > li {
      margin-left: 11px;
      margin-right: 11px;
    }
  }
}
.text-right {
  .nav-filters,
  &.nav-filters {
    > li {
      margin-left: 22px;
      margin-right: 0;
    }
  }
}
