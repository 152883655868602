.basket-nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .btn {
    flex: 1;
    max-width: 160px;

    // Allow buttons to use more space on very small screens.
    @media #{$mobile-port} {
      flex-basis: auto;
      flex-grow: 0.5;
    }

    // Hide checkout button on desktop.
    @media (min-width: $screen-sm-min) {
      flex: none;
      max-width: none;
      &.btn-checkout {
        display: none;
      }
    }
  }
}

.btn-checkout.btn-checkout-bottom {
  width: 100%;
  display: block;
  line-height: 44px;
  height: 44px;
  margin: 0;

  @media (min-width: $screen-sm-min) {
    width: auto;
    height: auto;
    padding: 0 80px;
    display: inline;
  }
}
