.arrow-icon {
  width: 11px;
  height: 11px;
  vertical-align: -1px;

  @media (min-width: $screen-sm-min) {
    width: 12px;
    height: 12px;
  }
}

.arrow-icon-left {
  margin-right: 5px;
}

.arrow-icon-right {
  margin-left: 8px;
  transform: rotate(180deg);
}
