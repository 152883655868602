/*
 * M-Store | Modern E-Commerce Template
 * Copyright 2016 rokaux
 * Theme Styles
 * Dependencies: Bourbon
 */

// Bourbon SASS Mixins Library
@import 'bourbon/bourbon'; // http://bourbon.io/

//@import "~bootstrap/scss/bootstrap";
// Core variables and mixins
@import 'basics';

@import '../../a11y';

// Core CSS
@import 'scaffolding';
@import 'typography';
@import 'forms';

// Components
@import 'social-buttons';
@import 'navbar';
@import 'components';
@import 'navs';

// Body blocks styles.
//@import 'bodyBlocks/bb-styles';

@import 'container';

@import 'falcon/falcon';

@import '../icons/material-icons.min.css';
